<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              lg="6"
              class="bg-login d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="9">
                      <div>
                        <h2 class="display-5 text-white text-shadow fw-medium">
                          Elegant Design with unlimited features, built with
                          love
                        </h2>
                        <p class="mt-4 text-dark op-5 font-weight-normal">
                          Wrappixel helps developers to build organized and
                          well-coded admin dashboards full of beautiful and
                          feature rich modules.
                        </p>
                        <b-button
                          size="lg"
                          variant="warning"
                          class="mt-4 text-capitalize"
                          >Learn More</b-button
                        >
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col
              lg="6"
              class="d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="6">
                      <b-img src="@/assets/images/owrent.png" style="max-width:150px" />
                      <h2 class="font-weight-bold mt-4">Sign in</h2>
                      <h6 class="mb-4">
                        Don't have an account?
                        <b-link to="/auth/register" class
                          >Sign Up</b-link
                        >
                      </h6>

                      <b-form>
                        <b-form-group>
                          <b-form-input
                            id="txt-email"
                            type="text"
                            placeholder="Enter email"
                            class="mb-3"
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-email"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-password"
                            type="password"
                            placeholder="Enter Password"
                            class="mb-3"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-password"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>

                        <!--<div class="d-flex align-items-center">
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="checkbox"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                          >
                            I accept the terms and use
                          </b-form-checkbox>

                          <div class="ml-auto">
                            <a href="javascript:void(0)" class="link"
                              >Forgot password?</a
                            >
                          </div>
                        </div>-->
                        <b-button
                          variant="primary"
                          :disabled="$v.form.$invalid"
                          size="lg"
                          block
                          class="mt-4"
                          @click="onLogin()"
                          >Sign In</b-button
                        >
                      </b-form>
                      <!--<div
                        class="d-flex align-items-center justify-content-center mt-4"
                      >
                        <b-badge
                          href="#"
                          pill
                          variant="info"
                          class="mr-2 px-3 py-2 d-flex align-items-center"
                        >
                          <feather
                            type="twitter"
                            class="feather-sm mr-2"
                          ></feather>
                          Twitter
                        </b-badge>
                        <b-badge
                          href="#"
                          pill
                          variant="dark"
                          class="px-3 py-2 d-flex align-items-center"
                        >
                          <feather
                            type="github"
                            class="feather-sm mr-2"
                          ></feather
                          >Github
                        </b-badge>
                      </div>-->
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "FullLogin",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    checkbox: false,
  }),
  computed: {
    ...mapState(["isAuthenticated"])
    /*...mapGetters({
      isAuthenticated: 'sanctum/isAuthenticated',
      user: 'sanctum/getUser',
    }),*/
  },
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(4),
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    //...mapActions({
    //  login: 'sanctum/login',
    //  logout: 'sanctum/logout',
    //}),
    ...mapActions(["signInMe"]),
    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },
    onLogin() {
      this.signInMe(this.form)
        .then((res) => {
          if(res && 'status' in res && res.status >= 400){
            this.$bvToast.toast(res.data, {
              title: "Something Went Wrong",
              variant: 'danger',
              solid: true
            })
          }
          else{
            this.$router.push({name: "Starter"})
          }
            // now you are logged in
            // the XSRF-Token was fetched and stored
            // and also the session cookie stored
            // all further subsequent request are authenticated

            // with activated vuex feature the login action on success
            // dispatch automatically the fetchUser action and store 
            // the authenticated user in the vuex store.
            // It also set the isAuthenticated state to true
            // so you can check anywhere in your application if the user is authenticated or not
        })
    },
    onLogout() {
      this.logout()
        .then(response => {
            console.log(response)
            // now you are logged out
            // and the state of the vuex sanctum module are cleared.
        })
        .catch(error => {
            console.log(error)
            // whoops, something went wrong
        });
    },
  },
};
</script>
<style>
.bg-login{
  background: url("../../assets/images/construction-site-safety-1.jpg") no-repeat;
  background-size: cover;
}
</style>
